<template>
    <in-layout>
    <div slot="list">
      <v-layout class="pa-5" wrap>
        <v-flex xs12 class="mb-3">
          <v-layout justify-space-between align-center>
            <span class="title">{{ profile.name }}</span>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <template
            row
            v-for="(permission, index) in profile.permissions"
            align-center
            wrap
          >
            <v-flex xs12 :key="permission.id">
              <v-layout class="mb-2" column>
                <v-layout align-end justify-space-between>
                  <strong class="grey--text mr-4">{{ permission.entity }}</strong>
                  <v-btn
                    class="font-weight-bold"
                    flat
                    color="primary"
                    @click="toggleAllFromEntity(index)"
                  >
                    Ativar Todos
                  </v-btn>
                </v-layout>
                <v-divider class="mb-4"/>

                <v-layout justify-space-between wrap>
                  <v-flex xs12>
                    <v-toolbar
                      v-for="capability in permission.capabilities"
                      :key="capability.label"
                      height="60"
                    >
                      <v-toolbar-title class="subheading font-weight-bold">
                        {{ capability.label }}
                      </v-toolbar-title>
                      <v-spacer />
                      <v-toolbar-items>
                        <v-switch height="50" v-model="capability.value" />
                      </v-toolbar-items>
                    </v-toolbar>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </template>
        </v-flex>
      </v-layout>
    </div>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import { forEach } from 'lodash';

export default {
  name: 'ManageProfile',
  components: {
    InLayout,
  },
  data() {
    return {
      profile: {
        id: '2',
        name: 'Gerador',
        permissions: [
          {
            entity: 'MTR',
            capabilities: {
              canList: { label: 'Listar', value: true },
              canCreate: { label: 'Cadastrar', value: true },
              canEdit: { label: 'Editar', value: true },
              canReceive: { label: 'Receber', value: false },
              canEditReceivement: { label: 'Editar Recebimento', value: false },
              canReject: { label: 'Rejeitar', value: false },
              canDownloadPDF: { label: 'Visualizar PDF', value: true },
              canDownloadReceivementReportPDF: { label: 'Visualizar Relatório de Recebimento PDF', value: false },
            },
          },
          {
            entity: 'CDF',
            capabilities: {
              canList: { label: 'Listar', value: false },
              canCreate: { label: 'Cadastrar', value: false },
              canDownloadPDF: { label: 'Visualizar PDF', value: false },
            },
          },
          {
            entity: 'Configurações',
            capabilities: {
              canConfigureCompany: { label: 'Empresa', value: false },
              canConfigureEmployees: { label: 'Colaboradores', value: false },
              canConfigureAccess: { label: 'Acesso', value: false },
            },
          },
        ],
      },
    };
  },
  methods: {
    toggleAllFromEntity(index) {
      const capabilities = { ...this.profile.permissions[index].capabilities };
      forEach(capabilities, (capability) => {
        capability.value = true;
      });
      this.profile.permissions[index].capabilities = { ...capabilities };
    },
  },
};
</script>
