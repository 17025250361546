var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("in-layout", [
    _c(
      "div",
      { attrs: { slot: "list" }, slot: "list" },
      [
        _c(
          "v-layout",
          { staticClass: "pa-5", attrs: { wrap: "" } },
          [
            _c(
              "v-flex",
              { staticClass: "mb-3", attrs: { xs12: "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: { "justify-space-between": "", "align-center": "" },
                  },
                  [
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.profile.name)),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _vm._l(_vm.profile.permissions, function (permission, index) {
                  return [
                    _c(
                      "v-flex",
                      { key: permission.id, attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { staticClass: "mb-2", attrs: { column: "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "align-end": "",
                                  "justify-space-between": "",
                                },
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "grey--text mr-4" },
                                  [_vm._v(_vm._s(permission.entity))]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: { flat: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleAllFromEntity(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Ativar Todos\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("v-divider", { staticClass: "mb-4" }),
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  "justify-space-between": "",
                                  wrap: "",
                                },
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  _vm._l(
                                    permission.capabilities,
                                    function (capability) {
                                      return _c(
                                        "v-toolbar",
                                        {
                                          key: capability.label,
                                          attrs: { height: "60" },
                                        },
                                        [
                                          _c(
                                            "v-toolbar-title",
                                            {
                                              staticClass:
                                                "subheading font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(capability.label) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-toolbar-items",
                                            [
                                              _c("v-switch", {
                                                attrs: { height: "50" },
                                                model: {
                                                  value: capability.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      capability,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "capability.value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }